import axios, { AxiosError } from 'axios';
import _, { isEqual, isNil, uniqBy } from 'lodash';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from 'src/api';
import { FullscreenSpinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import backgroundLines from 'src/images/background-lines.svg';
import fadedCircleBg from 'src/images/faded-circle-bg.svg';
import logoDealopsTarget from 'src/images/logos/dealops-target.svg';
import { Organization, User } from '../../types';
import FlowProgressBar from './FlowProgressBar';
import {
  PenguinAdditionalData,
  PenguinPricingFlow,
} from './Penguin/penguin_types';
import Step1ProductsAndVolume from './Step1ProductsAndVolume';
import Step2Calculator from './Step2Calculator';
import {
  DealopsPricingFlow,
  PricingFlow,
  PricingFlowCommon,
  PricingFlowMutableFields,
  PricingFlowReadonlyFields,
  PricingFlowStage,
  PricingFlowType,
  PRICING_FLOW_MUTABLE_KEYS,
} from './types';

import { datadogRum } from '@datadog/browser-rum';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import { getOpportunityIdFromIdOrUrl } from '../../utils/formatters';
import { classNames } from '../App';
import AlpacaPricingFlowPage from './Alpaca/AlpacaPricingFlowPage';
import {
  AlpacaAdditionalData,
  AlpacaPricingCurve,
  AlpacaPricingFlow,
  AlpacaProductPrice,
} from './Alpaca/alpaca_types';
import { addAllDerivedAggregationsToPricingFlow } from './Alpaca/alpaca_utils';
import ComplexDemoPricingFlowPage from './ComplexDemo/ComplexDemoPricingFlowPage';
import PenguinPricingFlowPage from './Penguin/PenguinPricingFlowPage';
import pricingCurveRegistry from './Penguin/pricing_curve_registry';

interface PricingFlowProps {
  user: User;
  organization: Organization;
}

const IDLE_TIMEOUT_MS = 3 * 60 * 1000;

/** Returns a copy of a quote with only the specified products **/
function quoteWithProducts(quote: unknown, desiredProducts: string[]) {
  if (!quote || typeof quote !== 'object' || !('products' in quote)) {
    // Missing or broken quote, don't do anything
    return quote;
  }

  return { ...quote, products: _.pick(quote.products, desiredProducts) };
}

/** Returns a flow with the unused products removed from the quotes **/
function withoutUnusedProducts(flow: PricingFlowCommon) {
  const { products, recommendedQuote, manualQuote } = flow;

  if (!products) {
    // The types say this shouldn't happen, but it does at step 1, so let's
    // leave things as they are for now
    return flow;
  }
  const uniqueProducts = uniqBy(products, 'id');
  if (uniqueProducts.length < products.length) {
    datadogRum.addError(
      new Error(
        `found duplicate products to pricing flow ${flow.id}. They will be removed by this fallback, but it may indicate a bug in some other code`,
      ),
      { uniqueProducts, products },
    );
  }

  const remainingProductNames = uniqueProducts.map((p) => p.id ?? p.name);

  return {
    ...flow,
    products: uniqueProducts,
    manualQuote: quoteWithProducts(manualQuote, remainingProductNames),
    recommendedQuote: quoteWithProducts(
      recommendedQuote,
      remainingProductNames,
    ),
  };
}

const PricingFlowContext = createContext<
  | {
      pricingFlow: PricingFlowCommon;
      updateFlow: (
        pricingFlow: PricingFlowCommon,
        showLoading?: boolean,
      ) => void;
      setStage: (params: {
        stage: PricingFlowCommon['stage'] | null;
        customStage: string | null;
        otherAdditionalData?: object;
      }) => void;
      loading: boolean;
      restartInteractionTracking: (cause: InteractionCause) => void;
      editMode: boolean;
    }
  | undefined
>(undefined);

function getPricingCurveForAlpacaProduct(
  productPrice: AlpacaProductPrice,
  pricingFlow: AlpacaPricingFlow,
): AlpacaPricingCurve {
  const curves = productPrice.pricingCurves;
  if (curves?.length > 0) {
    curves.sort((pcA, pcB) => {
      return pcA.priority - pcB.priority;
    });
    // find the relevant pricing curve
    for (const curve of curves) {
      if (pricingCurveRegistry.hasOwnProperty(curve.condition)) {
        // TODO(george) when we implement a real condition, this needs to take
        // in the pricing flow as an argument
        if (pricingCurveRegistry[curve.condition](pricingFlow)) {
          console.log(`activating ${curve.condition}`);
          return curve;
        }
      }
    }
    return curves[curves.length - 1];
  } else {
    datadogRum.addError(
      `Did not find pricing curves for ${productPrice.name} ${productPrice.id}`,
    );
    return {} as any;
  }
}

type CurrentAlpacaPricingCurves = { [productId: string]: AlpacaPricingCurve };
function getCurrentAlpacaPricingCurves(pricingFlow: AlpacaPricingFlow) {
  const productPrices = Object.values(
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
  );
  return productPrices.reduce((acc, productPrice) => {
    acc[productPrice.id] = getPricingCurveForAlpacaProduct(
      productPrice,
      pricingFlow,
    );
    return acc;
  }, {} as CurrentAlpacaPricingCurves);
}

function getCurrentPricingCurves(pricingFlow: PricingFlowCommon) {
  switch (pricingFlow.type) {
    case PricingFlowType.ALPACA:
      return getCurrentAlpacaPricingCurves(pricingFlow as AlpacaPricingFlow);
    case PricingFlowType.PENGUIN:
    case PricingFlowType.COMPLEX_DEMO:
    default:
      return {};
  }
}

function addDerivedAggregationsToPricingFlow(pricingFlow: PricingFlowCommon) {
  switch (pricingFlow.type) {
    case PricingFlowType.DEALOPS:
    case PricingFlowType.PENGUIN:
    case PricingFlowType.COMPLEX_DEMO:
      return pricingFlow;
    case PricingFlowType.ALPACA:
      return addAllDerivedAggregationsToPricingFlow(
        pricingFlow as AlpacaPricingFlow,
      );
    default:
      datadogRum.addError(`Unexpected pricing flow type ${pricingFlow.type}`);
      return pricingFlow;
  }
}

export function usePricingFlowContext<
  T extends
    | PricingFlowCommon
    | PenguinPricingFlow
    | AlpacaPricingFlow
    | DealopsPricingFlow,
>() {
  const pricingFlowContext = useContext(PricingFlowContext);
  if (pricingFlowContext == null) {
    throw new Error(
      'You should not be using the PricingFlowContext outside of the provider',
    );
  }
  const { pricingFlow } = pricingFlowContext;
  return {
    ...pricingFlowContext,
    pricingFlow: pricingFlow as T,
  };
}

function usePricingFlow({
  opportunityId,
  user,
}: {
  opportunityId: string;
  user: User;
}) {
  const [loading, setLoading] = useState(true);
  const editMode = user.permissions.includes('edit_pricing_flow');

  // This is the section of pricingFlow the user can update
  const [pricingFlowMutableFields, setPricingFlowMutableFields] =
    useState<PricingFlowMutableFields | null>(null);

  // This is the section of the pricingFlow the server calculates and isn't writable
  const [pricingFlowReadonlyFields, setPricingFlowReadonlyFields] =
    useState<PricingFlowReadonlyFields | null>(null);
  const createAnalyticsEvent = useAnalyticsContext();
  const { showToast } = useToast();
  const [previousInteractionTimeCause, setPreviousInteractionTimeCause] =
    useState<InteractionCause | null>(null);

  const pricingFlow: PricingFlowCommon | null =
    pricingFlowReadonlyFields == null || pricingFlowMutableFields == null
      ? null
      : {
          ...pricingFlowMutableFields,
          ...pricingFlowReadonlyFields,
        };

  const { restartInteractionTracking } = useTrackInteractionTime({
    onInteractionFinished: (timeSpent: number, cause: InteractionCause) => {
      if (!pricingFlow?.id) return;
      const { activeTimeSpent, idleTimeSpent } = (() => {
        if (
          cause === 'external' &&
          (previousInteractionTimeCause === 'external' ||
            isNil(previousInteractionTimeCause))
        ) {
          return { activeTimeSpent: 0, idleTimeSpent: timeSpent };
        }
        // We cap the amount of time spent to reduce impact from situations where
        // e.g. they keep the window open in the background and don't do anything
        const activeTimeSpent = Math.min(timeSpent, IDLE_TIMEOUT_MS);
        const idleTimeSpent = timeSpent - activeTimeSpent;
        return { activeTimeSpent, idleTimeSpent };
      })();
      createAnalyticsEvent({
        name: 'pricing_flow__added_interaction_time',
        eventData: {
          pricing_flow_id: pricingFlow?.id,
          active_time_spent: activeTimeSpent,
          idle_time_spent: idleTimeSpent,
          cause,
          previous_cause: previousInteractionTimeCause,
        },
      });
      // send active time
      for (let attempts = 0, sent = false; !sent && attempts < 5; attempts++) {
        if (attempts > 0) {
          datadogRum.addError(`adding interaction time failed!`, {
            attempts,
            pricingFlowId: pricingFlow.id,
            activeTimeSpent,
            idleTimeSpent,
          });
        }
        // #AddInteractionTimeErrors
        sent = navigator.sendBeacon(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/pricingFlow/${pricingFlow.id}/addInteractionTime?interactionTime=${activeTimeSpent}&idleTime=${idleTimeSpent}`,
        );
      }
      setPreviousInteractionTimeCause(cause);
    },
    isReadyToTrack: Boolean(pricingFlow?.id),
  });

  const setPricingFlow = (incomingPricingFlow: PricingFlowCommon) => {
    console.log('add derived aggregations to pricing flow');
    const pricingFlow =
      addDerivedAggregationsToPricingFlow(incomingPricingFlow);
    const { readonlyFields, mutableFields } = splitPricingFlow(pricingFlow);
    setPricingFlowMutableFields(() => {
      return {
        ...mutableFields,
        currentPricingCurves: getCurrentPricingCurves(pricingFlow),
      };
    });
    setPricingFlowReadonlyFields(() => readonlyFields);
  };

  const splitPricingFlow = (pricingFlow: PricingFlowCommon) => {
    const mutableFields: PricingFlowMutableFields = _.pick(
      pricingFlow,
      PRICING_FLOW_MUTABLE_KEYS,
    );

    const readonlyFields: PricingFlowReadonlyFields = _.omit(
      pricingFlow,
      PRICING_FLOW_MUTABLE_KEYS,
    );

    return {
      mutableFields,
      readonlyFields,
    };
  };

  useEffect(() => {
    console.log('opportunityId', opportunityId);
    const fetchPricingFlow = async () => {
      setLoading(true);
      try {
        const pricingFlowData = await (async () => {
          const existingPricingFlowRes = await api.get('pricingFlow', {
            opportunityId,
          });
          const { doesPricingFlowExist, pricingFlowData } =
            existingPricingFlowRes.data;
          if (doesPricingFlowExist) {
            console.log('Successfully fetched Pricing Flow: ', pricingFlowData);
            return pricingFlowData;
          } else {
            const newPricingFlowRes = await api.post('pricingFlow', {
              opportunityId,
            });
            console.log(
              'Successfully created Pricing Flow: ',
              newPricingFlowRes.data,
            );
            return newPricingFlowRes.data;
          }
        })();
        setPricingFlow({
          ...pricingFlowData,
          currentPricingCurves: getCurrentPricingCurves(pricingFlowData),
        });
      } catch (getError) {
        datadogRum.addError(getError);
        if (
          axios.isAxiosError(getError) &&
          (getError as AxiosError).response?.status === 404
        ) {
          showToast({
            title: 'No Opportunity was Found',
            subtitle: 'Opportunity ID: ' + opportunityId,
            type: 'error',
            autoDismiss: false,
          });
        } else {
          console.error('unknown error on POST PricingFlow:', getError);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPricingFlow();
  }, [opportunityId]);

  async function setStage(params: {
    stage: PricingFlowCommon['stage'] | null;
    customStage: string | null;
    otherAdditionalData?: object;
  }) {
    const { stage, customStage, otherAdditionalData } = params;
    if (!editMode) {
      console.log('USING SET STAGE');
      setLoading(true);
      const newStage = stage ?? pricingFlow?.stage;

      const newCustomStage =
        // @ts-ignore
        customStage ?? pricingFlow?.additionalData?.customStage;
      setPricingFlow({
        ...pricingFlow,
        additionalData: {
          ...(pricingFlow?.additionalData as object),
          ...(otherAdditionalData ?? {}), // USE THIS SPARINGLY
          customStage: newCustomStage,
        },
        stage: newStage,
      } as PricingFlowCommon);
      setLoading(false);
      return;
    }
    setLoading(true);
    let pricingFlowCopy = { ...pricingFlow };
    pricingFlowCopy.pricingSheetData = {};

    const response = await api.put('pricingFlow/' + pricingFlow?.id, {
      ...pricingFlowCopy,
      stage,
    });
    setPricingFlow(response.data);
    setLoading(false);
  }

  async function updateFlow(
    newPricingFlowMutableFieldsRaw: PricingFlowMutableFields,
    showLoading: boolean = true,
  ) {
    if (showLoading) {
      setLoading(true);
    }
    const cause: InteractionCause = (() => {
      // if the pricing config was modified, mark the pricing flow as modified
      if (
        !isEqual(
          newPricingFlowMutableFieldsRaw.products,
          pricingFlowMutableFields?.products,
        ) ||
        !isEqual(
          newPricingFlowMutableFieldsRaw.manualQuote,
          pricingFlowMutableFields?.manualQuote,
        )
      ) {
        return 'modifiedPricingFlow';
      }
      switch (pricingFlow?.type) {
        case PricingFlowType.ALPACA: {
          const newAdditionalDataPricingConfig = _.omit(
            newPricingFlowMutableFieldsRaw.additionalData as
              | AlpacaAdditionalData
              | undefined,
            ['treasuryStep', 'customStage'],
          );
          const oldAdditionalDataPricingConfig = _.omit(
            pricingFlow.additionalData as AlpacaAdditionalData | undefined,
            ['treasuryStep', 'customStage'],
          );
          if (
            !isEqual(
              newAdditionalDataPricingConfig,
              oldAdditionalDataPricingConfig,
            )
          ) {
            return 'modifiedPricingFlow';
          }
          return 'viewingPricingFlow';
        }
        case PricingFlowType.PENGUIN: {
          const newAdditionalDataPricingConfig = _.omit(
            newPricingFlowMutableFieldsRaw.additionalData as
              | PenguinAdditionalData
              | undefined,
            ['customStage'],
          );
          const oldAdditionalDataPricingConfig = _.omit(
            pricingFlow.additionalData as PenguinAdditionalData | undefined,
            ['customStage'],
          );
          if (
            !isEqual(
              newAdditionalDataPricingConfig,
              oldAdditionalDataPricingConfig,
            )
          ) {
            return 'modifiedPricingFlow';
          }
          return 'viewingPricingFlow';
        }
        case PricingFlowType.DEALOPS:
        case PricingFlowType.COMPLEX_DEMO:
        default:
          return 'modifiedPricingFlow';
      }
    })();
    restartInteractionTracking(cause);

    const pricingFlowRaw = {
      ...newPricingFlowMutableFieldsRaw,
      ...pricingFlowReadonlyFields,
    } as PricingFlow;

    const newPricingFlowRaw = withoutUnusedProducts(pricingFlowRaw);
    setPricingFlow(newPricingFlowRaw);

    // when sending to server remove pricingSheetData because it's too big
    let pricingFlowCopy = { ...newPricingFlowRaw };
    pricingFlowCopy.pricingSheetData = {};

    try {
      const response = await api.put(
        'pricingFlow/' + newPricingFlowRaw?.id,
        pricingFlowCopy,
      );
      const newPricingFlow: PricingFlow = response.data;

      // we call setPricingFlow twice because in some flow types the put response adds extra
      // information we display to the user.
      const { readonlyFields } = splitPricingFlow(newPricingFlow);
      setPricingFlowReadonlyFields(readonlyFields);
    } catch (err) {
      datadogRum.addError(err);
      console.error(err);
      showToast({
        title: 'Error updating the quote',
        subtitle: 'Please contact support@dealops.com',
        type: 'error',
      });
    }
    if (showLoading) {
      setLoading(false);
    }
  }

  return {
    pricingFlow,
    updateFlow,
    setStage,
    loading,
    restartInteractionTracking,
    editMode,
  };
}

type InteractionCause =
  | 'external' // e.g. closing browser window, navigating to another page
  | 'viewingPricingFlow'
  | 'modifiedPricingFlow';
// hook for tracking how long long the pricingFlow has been interacted with.
function useTrackInteractionTime({
  onInteractionFinished,
  isReadyToTrack,
}: {
  onInteractionFinished: (timeSpent: number, cause: InteractionCause) => void;
  isReadyToTrack: boolean;
}) {
  let lastEventTimestamp = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (!isReadyToTrack) return;
    startTracking();
    window.addEventListener('load', startTracking); // page load
    window.addEventListener('focus', startTracking); // moving towards window
    window.addEventListener('blur', stopTrackingFromExternalCause); // moving away from window
    document.addEventListener('visibilitychange', handleVisibilityChange); // switching tabs
    window.addEventListener('beforeunload', stopTrackingFromExternalCause); // closing tab

    return () => {
      stopTracking('external');
      window.removeEventListener('load', startTracking);
      window.removeEventListener('focus', startTracking);
      window.removeEventListener('blur', stopTrackingFromExternalCause);
      window.removeEventListener('focus', stopTrackingFromExternalCause);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', stopTrackingFromExternalCause);
    };
  }, [isReadyToTrack]);

  function startTracking() {
    lastEventTimestamp.current = Date.now();
  }

  function stopTrackingFromExternalCause() {
    return stopTracking('external');
  }
  function stopTracking(cause: InteractionCause) {
    if (lastEventTimestamp.current !== undefined) {
      onInteractionFinished(Date.now() - lastEventTimestamp.current, cause);
      lastEventTimestamp.current = undefined;
    }
  }

  function handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      startTracking();
    }

    if (document.visibilityState === 'hidden') {
      stopTracking('external');
    }
  }

  function restartInteractionTracking(cause: InteractionCause) {
    if (lastEventTimestamp.current !== undefined) {
      stopTracking(cause);
      startTracking();
    }
  }

  return { restartInteractionTracking };
}

export default function PricingFlowPage(props: PricingFlowProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const opportunityId = searchParams.get('opportunity');
  const createAnalyticsEvent = useAnalyticsContext();
  useEffect(() => {
    createAnalyticsEvent({
      name: 'pricing_flow_page__loaded',
      eventData: {
        opportunity_id: opportunityId,
      },
    });
  }, []);

  if (!opportunityId) {
    return (
      <>
        <div
          className="relative flex flex-col items-center justify-center bg-repeat-x"
          style={{ backgroundImage: `url(${backgroundLines})` }}
        >
          {/* Dealops target logo */}
          <div className="mt-36 h-24 w-24">
            <img
              className="absolute h-24 w-24"
              src={fadedCircleBg}
              alt="faded circle"
            />
            <div className="absolute ml-5 mt-5 flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
              <img className="h-7 w-7" src={logoDealopsTarget} alt="Dealops" />
            </div>
          </div>

          <h1 className="mx-auto max-w-7xl px-4 pt-6 text-center text-2xl font-semibold sm:px-6 lg:px-8">
            Hi {props.user.name?.split(' ')[0]}, let's work on pricing!
          </h1>
          <p className="text-l mx-auto max-w-7xl px-4 pt-2 text-center text-gray-700 sm:px-6 lg:px-8">
            Enter a Salesforce opportunity ID to get started.
          </p>

          <form
            onSubmit={(e) => {
              e.preventDefault();

              const id = getOpportunityIdFromIdOrUrl(
                e.currentTarget.opportunity.value,
              );
              searchParams.set('opportunity', id);
              setSearchParams(searchParams);
            }}
            className="mx-auto mt-8 block sm:mx-auto sm:w-full sm:max-w-sm"
          >
            <div className="mb-4">
              <input
                type="text"
                name="opportunity"
                required
                placeholder="Enter Opportunity ID or URL"
                className="block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-fuchsia-800 focus:outline-none focus:ring-fuchsia-800 sm:text-sm"
              />
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-fuchsia-900 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-fuchsia-950"
              >
                Find Opportunity
              </button>
            </div>
          </form>
          <RecentOpportunities
            setId={(id) => {
              searchParams.set('opportunity', id);
              setSearchParams(searchParams);
            }}
            user={props.user}
          />
        </div>
      </>
    );
  } else {
    return (
      <PricingFlowForOpportunity
        opportunityId={opportunityId}
        user={props.user}
        organization={props.organization}
      />
    );
  }
}

interface Opportunity {
  Id: string;
  Name: string;
  StageName: string;
  CloseDate: string;
  Amount: number;
  Account: {
    Name: string;
  };
  Owner: {
    Name: string;
  };
}
function RecentOpportunities(props: {
  setId: (id: string) => void;
  user: User;
}) {
  const [opportunities, setOpportunities] = useState<Opportunity[]>([]);
  const [startIndex, setStartIndex] = useState(0);
  useEffect(() => {
    if (props.user.salesforceUserId == null) {
      console.log('No salesforce user ID, will not fetch opportunities');
      return;
    }
    const fetchOpportunities = async () => {
      try {
        const response = await api.get('opportunities');
        setOpportunities(response.data);
      } catch (err) {
        datadogRum.addError(err);
        console.error(err);
      }
    };

    fetchOpportunities();
  }, []);

  if (opportunities.length === 0) {
    return null;
  }

  return (
    <div className="my-8 w-7/12">
      <div className="font-md text-gray-900 pb-4">Recent opportunities</div>
      <ul role="list" className="divide-y divide-gray-100">
        {opportunities.slice(startIndex, startIndex + 5).map((opportunity) => (
          <li
            key={opportunity.Id}
            className="flex items-center justify-between gap-x-6 py-2"
          >
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {opportunity.Name}
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                  Stage: {opportunity.StageName}
                </p>
              </div>
            </div>
            <button
              className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap"
              onClick={() => props.setId(opportunity.Id)}
            >
              Price this opportunity &rarr;
            </button>
          </li>
        ))}
      </ul>
      <div className="flex gap-x-4 mt-4">
        <button
          className={classNames(
            'flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:outline-offset-0',
            startIndex === 0 ? 'bg-gray-200 ' : 'bg-white hover:bg-gray-50 ',
          )}
          onClick={() => setStartIndex((startIndex) => startIndex - 5)}
          disabled={startIndex === 0}
        >
          Back
        </button>
        <button
          className={classNames(
            'flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:outline-offset-0',
            startIndex + 5 >= opportunities.length
              ? 'bg-gray-200 '
              : 'bg-white hover:bg-gray-50 ',
          )}
          onClick={() => setStartIndex((startIndex) => startIndex + 5)}
          disabled={startIndex + 5 >= opportunities.length}
        >
          View next 5 opportunities
        </button>
      </div>
    </div>
  );
}

function PricingFlowForOpportunity(props: {
  opportunityId: string;
  user: User;
  organization: Organization;
}) {
  const {
    pricingFlow,
    updateFlow,
    setStage,
    loading,
    restartInteractionTracking,
    editMode,
  } = usePricingFlow({ opportunityId: props.opportunityId, user: props.user });
  const createAnalyticsEvent = useAnalyticsContext();
  // We only want to log the `pricing_flow__viewed` event twice: once while the
  // pricing flow is loading, and once after it has finished loading
  const [hasLoggedViewedEvent, setHasLoggedViewedEvent] = useState<{
    preLoad: boolean;
    postLoad: boolean;
  }>({ preLoad: false, postLoad: false });
  useEffect(() => {
    if (loading && !hasLoggedViewedEvent.preLoad) {
      createAnalyticsEvent({
        name: 'pricing_flow__viewed',
        eventData: {
          pricing_flow_id: pricingFlow?.id,
          opportunity_id: props.opportunityId,
          is_loading: loading,
          edit_mode: editMode,
        },
      });
      setHasLoggedViewedEvent((hasLogged) => {
        return { ...hasLogged, preLoad: true };
      });
    }
    if (!loading && !hasLoggedViewedEvent.postLoad) {
      createAnalyticsEvent({
        name: 'pricing_flow__viewed',
        eventData: {
          pricing_flow_id: pricingFlow?.id,
          opportunity_id: props.opportunityId,
          is_loading: loading,
          edit_mode: editMode,
        },
      });
      setHasLoggedViewedEvent((hasLogged) => {
        return { ...hasLogged, postLoad: true };
      });
    }
  }, [loading]);

  if (loading || pricingFlow === null) {
    return <FullscreenSpinner />;
  }

  let pricingFlowPage = null;
  switch (pricingFlow.type) {
    case PricingFlowType.ALPACA:
      pricingFlowPage = (
        <AlpacaPricingFlowPage
          organization={props.organization}
          // todo(seb): handle interactionTracking?
          user={props.user}
        />
      );
      break;
    case PricingFlowType.PENGUIN:
      pricingFlowPage = (
        <PenguinPricingFlowPage
          organization={props.organization}
          user={props.user}
        />
      );
      break;
    case PricingFlowType.COMPLEX_DEMO:
      pricingFlowPage = (
        <ComplexDemoPricingFlowPage
          organization={props.organization}
          user={props.user}
        />
      );
      break;
    case PricingFlowType.DEALOPS:
      // These need to be migrated to their own PricingFlowPage components
      pricingFlowPage = (
        <>
          <FlowProgressBar
            stage={pricingFlow?.stage}
            setStage={(stage) => setStage({ stage, customStage: null })}
          />
          {pricingFlow?.stage === PricingFlowStage.ADD_PRODUCTS ? (
            <Step1ProductsAndVolume
              pricingFlow={pricingFlow}
              updateFlow={updateFlow}
              setStage={(stage) => setStage({ stage, customStage: null })}
              user={props.user}
            />
          ) : null}

          {pricingFlow?.stage === PricingFlowStage.CALCULATE_PRICE ? (
            <Step2Calculator
              pricingFlow={pricingFlow}
              updateFlow={updateFlow}
              setStage={(stage) => setStage({ stage, customStage: null })}
            />
          ) : null}
        </>
      );
      break;
    default:
      pricingFlowPage = (
        <div>Unknown pricing flow type: {pricingFlow.type}</div>
      );
  }

  return (
    <PricingFlowContext.Provider
      value={{
        pricingFlow,
        updateFlow,
        setStage,
        loading,
        restartInteractionTracking,
        editMode,
      }}
    >
      {pricingFlowPage}
    </PricingFlowContext.Provider>
  );
}
